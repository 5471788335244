import React from "react";
import Navbar from "../../components/utility/navbar/navbar";
import Footer from "../../components/utility/Footer/Footer";
import TransHook from "../../hooks/translation/trans-hook";

const Error404 = () => {
  const { t } = TransHook();
  return (
    <>
      <Navbar />
      <div>
        <h1
          style={{
            fontSize: "200px",
            alignItems: "center",
            marginTop: "200px",
          }}
        >
          {t("error404")}
        </h1>
      </div>
      <Footer />
    </>
  );
};

export default Error404;
