import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useGetShippingPriceInfoQuery } from "../../RTK/Api/ShippingPriceApi";

const GetShippingPricesHook = () => {
  const [shippingPrices, setShippingPrices] = useState([]);
  const { data, isError } = useGetShippingPriceInfoQuery();

  if (isError) toast.error(isError);

  useEffect(() => {
    if (data) setShippingPrices(data?.data);
  }, [data]);

  return { shippingPrices };
};

export default GetShippingPricesHook;
