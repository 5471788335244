import React, { useEffect, useState } from "react";
import "./navbar-mobile.css";
import NavBarHook from "../../../hooks/HomePage/NavBarHook";
import { Link, useNavigate } from "react-router-dom";
import { useGetCartInfoQuery } from "../../../RTK/Api/CartApi";
import { useGetWishlistInfoQuery } from "../../../RTK/Api/WishlistApi";
import { toast } from "react-toastify";
import {
  BoxArrowInLeft,
  Cart,
  Heart,
  List,
  Person,
  X,
} from "react-bootstrap-icons";
import { useGetParentCategoryInfoQuery } from "../../../RTK/Api/CategoryApi";
import SearchBar from "../Search/SearchBar";
import TransHook from "../../../hooks/translation/trans-hook";

const NavbarMobile = () => {
  const navigate = useNavigate();
  const { signedIn, userInfo, companyLogo, LogOut } = NavBarHook();
  const { data: cartInfo, cartError } = useGetCartInfoQuery();
  if (cartError) toast.error(cartError);

  const { data: wishlistInfo, wishlistError } = useGetWishlistInfoQuery();
  if (wishlistError) toast.error(wishlistError);

  const [isNavVisible, setIsNavVisible] = useState(false);

  const toggleNavbar = () => setIsNavVisible(!isNavVisible);

  const [ParentCategories, setParentCategories] = useState([]);
  const { data: category, error: categoryError } =
    useGetParentCategoryInfoQuery();
  if (categoryError) toast.error(categoryError);
  useEffect(() => {
    setParentCategories(
      category?.data?.filter((category) => !category.parentCategory)
    );
  }, [category]);

  const { t } = TransHook();

  return (
    <>
      <div className="d-flex align-items-center justify-content-around">
        <div className="d-flex align-items-center">
          <List size={28} style={{ margin: "5px" }} onClick={toggleNavbar} />
        </div>
        <Link
          to="/"
          style={{ color: "#777", width: "128px", padding: "5px 0" }}
        >
          {/* <h3 style={{ color: "#de961d" }}>Smart E-commerce</h3> */}
          <img src={companyLogo} alt="Logo" />
        </Link>
        <div>
          <Link
            to="/cart"
            className="header-icon position-relative m-0"
            style={{ color: "#777" }}
          >
            <Cart size={25} style={{ margin: "10px" }} />
          </Link>
        </div>
      </div>

      <SearchBar />

      <div className={`side-menu ${isNavVisible ? "visible" : ""}`}>
        <div className="d-flex align-items-center justify-content-between">
          <h4>
            <Link to="/" style={{ color: "#777" }}>
              <h2>Smart E-commerce</h2>
            </Link>
          </h4>{" "}
          <X size={28} onClick={toggleNavbar} />
        </div>
        <Link
          to="/my-wishlist"
          className="header-icon position-relative m-0 d-flex align-items-center"
          title="wishlist"
          style={{ color: "#777" }}
        >
          <Heart size={20} className="p-0 m-2" />
          <h4 className="p-0 m-2">{t("wishlist")}</h4>
        </Link>
        {signedIn ? (
          <Link className="d-flex align-items-center" to={"/account-details"}>
            <Person className="p-0 m-2" size={25} color="#777" />
            <h4 style={{ color: "#777" }} className="p-0 m-2">
              {userInfo?.name?.length > 0
                ? userInfo?.name?.substring(0, 10)
                : userInfo?.email?.substring(0, 10)}
            </h4>
          </Link>
        ) : (
          <Link className="d-flex align-items-center" to={"/login"}>
            <BoxArrowInLeft className="p-0 m-2" size={25} color="#777" />
            <h4 style={{ color: "#777" }} className="p-0 m-2">
              {t("signInRegister")}
            </h4>
          </Link>
        )}
        <br />
        <h3>{t("categories")}</h3>
        {ParentCategories &&
          ParentCategories.map((category, i) => {
            return (
              <Link
                className="my-5"
                style={{ fontSize: "14px" }}
                to={`/products/${category?._id}/category`}
                key={i}
              >
                {category?.name}
              </Link>
            );
          })}
      </div>
    </>
  );
};

export default NavbarMobile;
