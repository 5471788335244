import React from "react";

const NavbarTop = () => {
  const screen = localStorage.getItem("screen") || "large";
  return (
    <div
      className="justify-content-around d-flex py-3"
      style={{
        background: "#de961d",
        borderRadius: screen === "large" ? "0px 0px 10px 10px" : "",
      }}
    >
      <div className="announcement-container">
        <div className="announcement-wrapper">
          <span className="announcement-text">
            هذا الموقع لا يزال قيد التطوير
          </span>
          <span className="announcement-text">
            Bu site halen geliştirilme aşamasındadır
          </span>
          <span className="announcement-text">
            This site is still under development!
          </span>
        </div>
      </div>
    </div>
  );
};

export default NavbarTop;
