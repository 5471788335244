const calculateAndFormatPrice = (
  ecommercePrice,
  priceAfterDiscount,
  exchangeRate
) => {
  let finalPrice;

  if (priceAfterDiscount > 0) {
    finalPrice = priceAfterDiscount;
  } else {
    finalPrice =
      typeof ecommercePrice === "number" && typeof exchangeRate === "number"
        ? ecommercePrice * exchangeRate
        : 0;
  }

  return formatPrice(finalPrice);
};

function formatPrice(value) {
  if (typeof value !== "number") return "";

  const [integerPart, decimalPart] = value.toString().split(".");

  // Check if the first character after the decimal is '0'
  if (decimalPart?.charAt(0) === "0") {
    return integerPart; // Return only the integer part
  }

  // Ensure there are at least two decimal places
  const formattedDecimal = (decimalPart || "").substring(0, 2).padEnd(2, "0");

  return `${integerPart}.${formattedDecimal}`;
}

export default calculateAndFormatPrice;
