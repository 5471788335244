import React from "react";
import "./Slider.css";
import OfferSliderHook from "../../../hooks/HomePage/OfferSliderHook";
import { toast } from "react-toastify";
import TransHook from "./../../../hooks/translation/trans-hook";

const OfferSlider = ({ type }) => {
  const { sliderInfo, sliderIsLoading, sliderError } = OfferSliderHook();
  if (sliderError) {
    console.error(sliderError);
    toast.error(sliderError);
  }

  const { t } = TransHook();
  const lang = localStorage.getItem("language");
  const screen = localStorage.getItem("screen") || "large";

  if (sliderInfo?.data[1]?.images?.length > 0) {
    return (
      <div className="offerSlider">
        <h2
          className={`section-title ls-n-10 pb-3 m-b-4 ${
            lang === "ar" ? "arabic-text" : ""
          }`}
        >
          {t("specialOffers")}
        </h2>
        <swiper-container
          slides-per-view={
            screen === "large" ? (type === "main" ? "1" : "2") : "1"
          }
          className="mySwiper"
          speed="500"
          space-between="15"
          loop="true"
        >
          {!sliderIsLoading
            ? sliderInfo?.data?.length > 0
              ? sliderInfo?.data[1].images.map((slide, i) => {
                  return (
                    <swiper-slide key={i}>
                      <img
                        src={slide}
                        alt=""
                        style={
                          screen === "large"
                            ? { height: "300px" }
                            : { height: "200px", objectFit: "fill" }
                        }
                      />
                    </swiper-slide>
                  );
                })
              : null
            : null}
        </swiper-container>
      </div>
    );
  } else {
    return null;
  }
};

export default OfferSlider;
