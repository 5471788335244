import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
import { Plus } from "react-bootstrap-icons";
import CheckoutHook from "../../hooks/cart/CheckoutHook";
import TransHook from "../../hooks/translation/trans-hook";
import AddressCard from "./AddressCard";
import GetPaymentMethodsHook from "../../hooks/PaymentMethods/GetPaymentMethodsHook";

const AddressDetails = ({ cartId, goBackToCart, proceedToCheckout }) => {
  const { t } = TransHook();
  const {
    addressGet,
    activeShipIndex,
    activeBilIndex,
    addingAddress,
    alias,
    fullName,
    city,
    details,
    postalCode,
    phone,
    cart,
    differentBilling,
    handleAliasChange,
    handleFullNameChange,
    handleCityChange,
    handleDetailsChange,
    handlePostalCodeChange,
    handlePhoneChange,
    save,
    cancel,
    setDifferentBilling,
    setAddingAddress,
    selectShipAddress,
    selectBilAddress,
  } = CheckoutHook(cartId);

  const { paymentMethods } = GetPaymentMethodsHook();
  const cur = JSON.parse(Cookies.get("CompanyCurrency"));

  // Calculate the total product cost and apply the addition rate
  const calculateTotalProductCost = () => {
    let totalProductCost = 0;

    cart?.cartItems?.forEach((item) => {
      totalProductCost += item.taxPrice * item.quantity;
    });

    return totalProductCost;
  };

  const totalProductCost = calculateTotalProductCost();

  const [onlinePrice, setOnlinePrice] = useState("");
  const [additionRate, setAdditionRate] = useState(0);
  const screen = localStorage.getItem("screen") || "large";

  useEffect(() => {
    if (paymentMethods) {
      const onlinePaymentMethod = paymentMethods.find(
        (method) => method.name === "onlinePayment"
      );

      const extraCharge = onlinePaymentMethod?.extraCharge || 0;
      const companyRatio = onlinePaymentMethod?.companyRatio || 0;
      setAdditionRate(extraCharge - companyRatio);

      const extraChargeAmount = totalProductCost * (extraCharge / 100);
      const companyPortion = extraChargeAmount * (companyRatio / 100);
      const customerExtraCharge = extraChargeAmount - companyPortion;
      const finalPrice = totalProductCost + customerExtraCharge;

      setOnlinePrice(finalPrice);
    }
  }, [paymentMethods, totalProductCost]);

  return (
    <div
      className={screen === "large" ? "d-flex" : ""}
      style={{ justifyContent: "space-around" }}
    >
      {/* Address cards START */}
      <div className={screen === "large" ? "col-lg-7" : ""}>
        <h2 className="step-title">{t("shippingDetails")}</h2>

        <div className="d-flex flex-wrap Adresses">
          {addressGet && addressGet?.data?.length > 0 ? (
            addressGet?.data?.map((item, i) => {
              const isActive = activeShipIndex === i;
              return (
                <div
                  className={`col-md-6 mb-3`}
                  key={i}
                  onClick={() => selectShipAddress(i, item)}
                >
                  <AddressCard
                    alias={item.alias}
                    name={item.name}
                    phone={item.phone}
                    details={item.details}
                    city={item.city}
                    postalCode={item.postalCode}
                    isActive={isActive}
                  />
                </div>
              );
            })
          ) : (
            <h3>{t("noAddresses")}</h3>
          )}
        </div>

        {/* Different billing START */}
        <div className="form-group">
          <div
            className="custom-control custom-checkbox mt-0"
            onClick={() => setDifferentBilling(!differentBilling)}
          >
            <input
              type="checkbox"
              checked={differentBilling}
              className="custom-control-input"
              id="different-shipping"
            />
            <label className="custom-control-label">
              {t("differentBilling")}
            </label>
          </div>
        </div>
        {/* Different billing END */}
        {differentBilling ? (
          <div className="d-flex flex-wrap" style={{ justifyContent: "unset" }}>
            {addressGet && addressGet?.data?.length > 0 ? (
              addressGet?.data?.map((item, i) => {
                const isActive = activeBilIndex === i;
                return (
                  <div
                    className={`col-md-6 mb-3`}
                    key={i}
                    onClick={() => selectBilAddress(i, item)}
                  >
                    <AddressCard
                      alias={item.alias}
                      name={item.name}
                      phone={item.phone}
                      details={item.details}
                      city={item.city}
                      postalCode={item.postalCode}
                      isActive={isActive}
                    />
                  </div>
                );
              })
            ) : (
              <h3>{t("differentBilling")}</h3>
            )}
          </div>
        ) : null}
        <div
          className="address-card col-md-12 text-center mb-4"
          onClick={() => setAddingAddress(!addingAddress)}
        >
          <Plus size={50} />
          <h3>{t("addNewAddress")}</h3>
        </div>

        {/* Address form START */}
        <form
          action="#"
          id="checkout-form"
          className={addingAddress ? "" : "collapse"}
        >
          <div className="address account-content mt-0 pt-2">
            <h4 className="title">{t("addNewAddress")}</h4>

            <form className="mb-2" action="#">
              <div className="row">
                <div className="form-group mb-3 col-md-6">
                  <label>
                    {t("addressTitleHomeWork")}{" "}
                    <span className="required">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder={t("home")}
                    required
                    value={alias}
                    onChange={handleAliasChange}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label>
                      {t("fullName")} <span className="required">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      required
                      value={fullName}
                      onChange={handleFullNameChange}
                    />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="form-group col-md-6">
                  <label>
                    {t("townCity")} <span className="required">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    required
                    value={city}
                    onChange={handleCityChange}
                  />
                </div>
                <div className="form-group col-md-6">
                  <label>
                    {t("address")} <span className="required">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    required
                    value={details}
                    onChange={handleDetailsChange}
                  />
                </div>
              </div>

              <div className="row">
                <div className="form-group col-md-6">
                  <label>
                    {t("postalCode")} <span className="required">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    required
                    value={postalCode}
                    onChange={handlePostalCodeChange}
                  />
                </div>

                <div className="form-group col-md-6 mb-3">
                  <label>
                    {t("phoneNumber")} <span className="required">*</span>
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    required
                    value={phone}
                    onChange={handlePhoneChange}
                  />
                </div>
              </div>

              <div className="form-footer mb-0">
                <div className="form-footer-right">
                  <span className="btn btn-dark py-4" onClick={() => save()}>
                    {t("save")}
                  </span>
                  <button
                    type="button"
                    className="btn btn-dark py-4"
                    onClick={() => cancel()}
                  >
                    {t("cancel")}
                  </button>
                </div>
              </div>
            </form>
          </div>
          {/* Contact END */}
        </form>
        {/* Address form END */}
      </div>
      {/* Address cards END */}

      {/* Order summary START */}
      <div className={screen === "large" ? "col-lg-4" : ""}>
        <div className="order-summary">
          <h3>{t("yourOrder")}</h3>

          <table className="table table-mini-cart">
            <thead>
              <tr>
                <td style={{ border: "none" }} colSpan="2">
                  {t("product")}
                </td>
              </tr>
            </thead>
            <tbody>
              {cart?.cartItems?.map((item, i) => {
                return (
                  <tr key={i}>
                    <td className="product-col">
                      <h3 className="product-title">
                        {item.name}&nbsp;×
                        <span className="product-qty">{item.quantity}</span>
                      </h3>
                    </td>

                    <td className="price-col">
                      <span>
                        {cur.currencyCode}
                        {(
                          item.taxPrice +
                          ((item.taxPrice * additionRate) / 100) * item.quantity
                        ).toFixed(2)}
                      </span>
                    </td>
                  </tr>
                );
              })}
            </tbody>
            <tfoot>
              <tr className="order-total">
                <td>
                  <h4>{t("total")}</h4>
                </td>
                <td colSpan={2}>
                  <b className="total-price">
                    <span>
                      {cur.currencyCode}
                      {parseFloat(onlinePrice).toFixed(2)}
                    </span>
                  </b>
                </td>
              </tr>
            </tfoot>
          </table>

          <div className="d-flex">
            <h4
              className="btn btn-dark btn-place-order mx-2"
              onClick={goBackToCart}
            >
              {t("backToCart")}
            </h4>
            <h4
              className="btn btn-dark btn-place-order mx-2"
              onClick={() => {
                if (
                  activeShipIndex === null ||
                  (differentBilling && activeBilIndex === null)
                ) {
                  toast.error(t("selectAddress"));
                } else {
                  proceedToCheckout(cartId);
                }
              }}
            >
              {t("proceedToCheckout")}
            </h4>
          </div>
        </div>
      </div>
      {/* Order summary END */}
    </div>
  );
};

export default AddressDetails;
