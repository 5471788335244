import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useGetPaymentMethodsInfoQuery } from "../../RTK/Api/PaymentMethodsApi";

const GetPaymentMethodsHook = () => {
  const [paymentMethods, setPaymentMethods] = useState([]);
  const { data, isError, isLoading } = useGetPaymentMethodsInfoQuery();

  if (isError) toast.error(isError);

  useEffect(() => {
    if (data) setPaymentMethods(data?.data);
  }, [data]);

  return { paymentMethods, isLoading };
};

export default GetPaymentMethodsHook;
