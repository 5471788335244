import { useEffect, useState } from "react";
import { ProductsEndPoint, WishlistEndPoint } from "../../api/GlobalData";
import useGetData from "../api/useGetData";
import usePostData from "./../api/usePostData";
import GetProductsHook from "./getProductsHook";

const GetOneProductHook = (id) => {
  const { isLoading, data, error } = useGetData(ProductsEndPoint + `/${id}`);

  // Log the error if existed
  if (error) console.error(error);

  const [quantity, setQuantity] = useState(0);

  // Set the sharing URL
  const url = `${window.location.href}`;
  const pageUrl = encodeURIComponent(url);
  const subject = encodeURIComponent("Check out this page");

  // Add to wishlist START
  const wishlistMutation = usePostData(WishlistEndPoint);
  const handleWishlist = () => {
    wishlistMutation.mutate({ productId: id });
  };

  // Set active tab (in product details like description, reviews...) START
  const [activeTab, setActiveTab] = useState("product-desc-content");
  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
  };

  // Set slides for the swiper depending on screen size START
  const [slides, setSlides] = useState(1920);
  useEffect(() => {
    const handleResize = () => {
      const windowWidth = window.innerWidth;
      if (windowWidth > 600) {
        setSlides(4);
      } else {
        setSlides(2);
      }
    };
    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Get top 10 sold products START
  const [top10Sold, setTop10Sold] = useState([{}]);
  const [productsData, isProductsLoading] = GetProductsHook();

  useEffect(() => {
    if (productsData) {
      const filteredProducts = productsData.filter(
        (product) => product.ecommerceActive && product.publish
      );
      const sortedProducts = [...filteredProducts].sort(
        (a, b) => b.sold - a.sold
      );
      const top10Products = sortedProducts.slice(0, 10);

      setTop10Sold(top10Products);
    }
  }, [productsData]);

  // Related products
  const [alternateProducts, setAlternateProducts] = useState([]);
  useEffect(() => {
    if (data?.data) setAlternateProducts(data?.data?.alternateProducts);
  }, [data]);

  // Set max amount for the product depending on its active count
  const [maxAmount, setMaxAmount] = useState();
  const [inputValue, setInputValue] = useState(1);
  useEffect(() => {
    if (!isLoading) {
      if (data.data) {
        setMaxAmount(data.data.activeCount);
        setInputValue(Math.min(inputValue, data.data.activeCount));
      } else {
        setMaxAmount(1);
      }
    }
  }, [isLoading]);

  // Handle quantity change START
  const handleInputChange = (event) => {
    const newValue = parseInt(event.target.value, 10);
    if (!isNaN(newValue)) {
      setInputValue(Math.min(newValue, maxAmount));
      setQuantity(event.target.value);
    }
  };

  return {
    data: data?.data,
    isLoading,
    quantity,
    pageUrl,
    subject,
    activeTab,
    slides,
    top10Sold,
    isProductsLoading,
    maxAmount,
    inputValue,
    alternateProducts,
    handleWishlist,
    setActiveTab,
    handleTabClick,
    handleInputChange,
  };
};

export default GetOneProductHook;
