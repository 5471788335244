import React, { useEffect, useState } from "react";
import NavbarMiddle from "./navbar-middle";
import { ToastContainer } from "react-bootstrap";
import NavbarMobile from "./navbar-mobile";
import NavbarTop from "./navbar-top";

const Navbar = () => {
  const [navbar, setNavbar] = useState("");
  useEffect(() => {
    const handleResize = () => {
      const windowWidth = window.innerWidth;
      if (windowWidth > 600) {
        setNavbar("large");
        localStorage.setItem("screen", "large");
      } else {
        setNavbar("small");
        localStorage.setItem("screen", "small");
      }
    };
    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="header">
      {navbar === "large" ? (
        <NavbarMiddle />
      ) : (
        <>
          <NavbarTop />
          <NavbarMobile />
        </>
      )}
      <ToastContainer autoClose={2000} />
    </div>
  );
};

export default Navbar;
