import { useEffect, useState } from "react";
import Cookies from "js-cookie";
import SetColors from "./SetColors";
import { useGetCompanyInfoQuery } from "../../RTK/Api/CompanyInfoApi";
import { useNavigate } from "react-router-dom";
import GetCompanyInfo from "../company/getCompanyInfo";

const NavBarHook = () => {
  const navigate = useNavigate();
  let companyLogo = "";
  const [signedIn, setSignedIn] = useState(!!Cookies.get("userData"));
  const [userInfo, setUserInfo] = useState({});
  const { data: companyInfo } = useGetCompanyInfoQuery();
  GetCompanyInfo();
  SetColors();
  // Check if companyInfo is defined and has elements before accessing its properties
  if (companyInfo?.data && companyInfo?.data.length > 0) {
    companyLogo = companyInfo.data[0]?.companyLogo;
  }

  useEffect(() => {
    if (signedIn) {
      const userDataString = Cookies.get("userData");
      if (userDataString) {
        const userData = JSON.parse(userDataString);
        setUserInfo(userData);
      }
    }
  }, [signedIn]);

  // Logout Functionality
  const LogOut = () => {
    if (signedIn) {
      Cookies.remove("Token");
      Cookies.remove("userData");
      setUserInfo("");
    }
    navigate("/");
    setSignedIn(false);
    window.location.reload();
  };

  return {
    signedIn,
    userInfo,
    companyLogo,
    LogOut,
  };
};

export default NavBarHook;
