import { useEffect, useState } from "react";
import {
  useAddCartItemMutation,
  useDecreaseCartItemMutation,
  useGetCartInfoQuery,
  useRemoveCartItemMutation,
} from "../../RTK/Api/CartApi";
import { toast } from "react-toastify";
import TransHook from "./../translation/trans-hook";

const CartHook = (proceedToAddress) => {
  const [cartTotal, setCartTotal] = useState();
  const { t } = TransHook();

  const [addCartItem, { error: addItemError }] = useAddCartItemMutation();
  if (addItemError) toast.error(t("failedToAddToCart"));
  const handleAddToCart = async (product) => {
    const { qr, taxPrice } = product;
    const ob = {
      qr,
      taxPrice,
      quantity: 1,
    };
    await addCartItem(ob)
      .unwrap()
      .then(() => toast.success(t("addedToCart")))
      .catch((error) => toast.error(error));
  };

  const [decreaseCartItem, { error: decreaseItemError }] =
    useDecreaseCartItemMutation();
  if (decreaseItemError) toast.error(t("cantDecrease"));
  const handleDecreaseCart = async (product) => {
    const { quantity, _id } = product;
    await decreaseCartItem({ itemId: _id, quantity: quantity - 1 })
      .unwrap()
      .then(() => toast.success(t("amountDecreased")))
      .catch((error) => toast.error(error));
  };

  const {
    data: cart,
    error: cartError,
    isLoading: isCartLoading,
  } = useGetCartInfoQuery();
  if (cartError) toast.error(cartError);

  const [removeCartItem, { error: removeError }] = useRemoveCartItemMutation();
  if (removeError) toast.error(removeError);
  const handleRemoveItem = async (itemId) => {
    try {
      await removeCartItem(itemId)
        .unwrap()
        .then(() => toast.success(t("itemRemoved")));
    } catch (error) {
      toast.error(error);
    }
  };

  useEffect(() => {
    if (!isCartLoading && cart) {
      setCartTotal(cart?.data?.totalCartPrice);
    }
  }, [isCartLoading, cart]);

  const submitCart = () => {
    if (cart?.data?._id !== null && cart?.data?._id !== undefined) {
      proceedToAddress(cart?.data?._id);
      const cartString = JSON.stringify(cart?.data);
      localStorage.setItem("cart", cartString);
    }
  };

  return {
    isCartLoading,
    cart,
    cartTotal,
    handleAddToCart,
    handleDecreaseCart,
    handleRemoveItem,
    submitCart,
  };
};

export default CartHook;
