import { BrowserRouter, Route, Routes } from "react-router-dom";
import HomePage from "./pages/Home/HomePage";
import ProductDetailsPage from "./pages/Product/ProductDetailsPage";
import Products from "./pages/Product/Products";
import MyDashboard from "./pages/Account/MyDashboard";
import AuthPage from "./pages/Auth/AuthPage";
import { useEffect, useState } from "react";
import CartPage from "./pages/Cart/CartPage";
import OrderDetails from "./components/Order/OrderDetails";
import CMSPage from "./pages/CmsPages/CMSPage";
import ForgotPassword from "./components/Auth/ForgotPassword";
// import ResetPassword from "./components/Auth/ResetPassword";
import Error404 from "./pages/Error/Error404";
import PaymentPage from "./pages/Payment/PaymentPage";
import AllReviews from "./components/Product/JSX/AllReviews";
import OrderComplete from "./components/Order/OrderComplete";

function App() {
  const [cuurrentUrl, setCurrentUrl] = useState("");
  useEffect(() => {
    setCurrentUrl(window.location.href);
  }, [cuurrentUrl]);

  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/login" element={<AuthPage />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          {/* <Route path="/resetPassword/:token" element={<ResetPassword />} /> */}
          <Route path="/product-details/:id" element={<ProductDetailsPage />} />
          <Route path="/product-details/:id/reviews" element={<AllReviews />} />
          <Route path="/products" element={<Products />} />
          <Route path="/products/:keyword" element={<Products />} />
          <Route path="/products/:id" element={<Products />} />
          <Route path="/category/:id" element={<Products />} />
          <Route path="/products/:id/:type" element={<Products />} />

          <Route
            path="/account-details"
            element={<MyDashboard activetab="account-details" />}
          />
          <Route
            path="/my-orders"
            element={<MyDashboard activetab="my-orders" />}
          />
          <Route path="/my-orders/:id" element={<OrderDetails />} />
          <Route
            path="/my-wishlist"
            element={<MyDashboard activetab="my-wishlist" />}
          />
          <Route
            path="/my-addresses"
            element={<MyDashboard activetab="my-addresses" />}
          />
          <Route path="/cart" element={<CartPage />} />
          <Route path="/CMS/:id" element={<CMSPage />} />
          <Route path="/error404" element={<Error404 />} />
          <Route path="/payment" element={<PaymentPage />} />
          <Route path="/order-complete" element={<OrderComplete />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}
export default App;
