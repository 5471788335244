import React from "react";
import "./SearchBar.css";
import { Search } from "react-bootstrap-icons";
import SearchHook from "../../../hooks/Search/SearchHook";
import TransHook from "../../../hooks/translation/trans-hook";

const SearchBar = () => {
  const { t } = TransHook();
  const {
    handleMouseDown,
    handleMouseUp,
    setSearchKey,
    set_show_Recent_list,
    handleKeyDown,
    settoggle_list,
    handleNavigate,
    handleKeywordClick,
    isClicking,
    SearchKey,
    show_Recent_list,
    ProductResults,
    recent_keyword,
    toggle_list,
  } = SearchHook();
  const lang = localStorage.getItem("language");
  const screen = localStorage.getItem("screen") || "large";

  return (
    <div
      className={`col-lg-6 col-md-8 col-11 mx-auto Search pb-3`}
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
    >
      <div className="row SearchBar c d-flex justify-content-center align-items-center">
        <div className="form col align-content-center align-items-center d-flex">
          <input
            type="text"
            className={`form-control form-input ${
              lang === "ar" ? "arabic-text" : ""
            }`}
            placeholder={t("searchPlaceholder")}
            onChange={(e) => {
              setSearchKey(e.target.value);
              set_show_Recent_list(false);
            }}
            onKeyDown={handleKeyDown}
            onFocus={() => settoggle_list(true)}
            onBlur={() => {
              setTimeout(() => {
                if (!isClicking) {
                  settoggle_list(false);
                }
              }, 0);
            }}
            value={SearchKey}
          />
          {/* <span className="left-pan"> */}
          <Search size={screen === "large" ? 20 : 16} />
          {/* </span> */}
        </div>
      </div>
      <div className={`Search_Result ${toggle_list ? "toggle_list" : ""} `}>
        {!show_Recent_list && (
          <div>
            <h4>{t("searchResults")}</h4>
            <ul className="Product_Result_list">
              {ProductResults?.data?.map((product) => (
                <li
                  key={product._id}
                  onClick={() => handleNavigate(product?._id)}
                >
                  <span>{product?.name}</span>
                  <span>{t("product")}</span>
                </li>
              ))}
            </ul>
          </div>
        )}

        {show_Recent_list && (
          <div>
            <h4>{t("recentSearches")}</h4>
            <ul className="Recent_Keyword">
              {recent_keyword?.slice(0, 6).map((keyword, index) => (
                <li
                  key={index}
                  style={{ borderBottom: "1px solid rgba(9,170,175,0.2)" }}
                  onClick={() => handleKeywordClick(keyword)}
                >
                  <span>{keyword}</span>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

export default SearchBar;
