import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import "./Checkout.css";
import creditCard from "../../assets/images/creditcard.png";
import payAtDoor from "../../assets/images/payatdoor.png";
import CheckoutHook from "./../../hooks/cart/CheckoutHook";
import TransHook from "../../hooks/translation/trans-hook";
import GetShippingPricesHook from "../../hooks/ShippingPrice/GetShippingPriceHook";
import GetPaymentMethodsHook from "../../hooks/PaymentMethods/GetPaymentMethodsHook";
import Loader from "./../Loaders/Loader";

const Checkout = ({ goBackToAddress, cartId }) => {
  const { t } = TransHook();
  const { cart, createOrder, isLoadingCheckout } = CheckoutHook(cartId);
  const cur = JSON.parse(Cookies.get("CompanyCurrency"));
  const lang = localStorage.getItem("language");
  const [bankInfo, setBankInfo] = useState({});

  const { paymentMethods, isLoading } = GetPaymentMethodsHook();
  const { shippingPrices } = GetShippingPricesHook();

  const [paymentMethod, setPaymentMethod] = useState("onlinePayment");
  const handlePaymentMethodChange = (event) => {
    setPaymentMethod(event.target.value);
  };

  const selectedPaymentMethod = paymentMethods?.find(
    (item) => item.name === paymentMethod
  );

  const calculateShippingCost = (product) => {
    const { width, length, height, weight } = product;

    const volumetricWeight = (length * width * height) / 5000;
    const effectiveWeight = Math.max(volumetricWeight, weight);

    if (!shippingPrices || !shippingPrices[0]?.prices) {
      console.error("Shipping prices data is missing or malformed.");
      return null;
    }

    const shippingPrice = shippingPrices[0]?.prices?.find(
      (price) =>
        effectiveWeight >= price.fromWeight && effectiveWeight <= price.toWeight
    );

    return shippingPrice ? shippingPrice.price : null;
  };

  // Calculate the total cart price with shipping and extra charges
  const [additionRate, setAdditionRate] = useState(0);
  useEffect(() => {
    if (paymentMethods) {
      const onlinePaymentMethod = paymentMethods.find(
        (method) => method.name === "onlinePayment"
      );

      const extraCharge = onlinePaymentMethod?.extraCharge || 0;
      const companyRatio = onlinePaymentMethod?.companyRatio || 0;
      setAdditionRate(extraCharge - companyRatio);

      const transferPaymentMethod = paymentMethods.find(
        (method) => method.name === "bankTransfer"
      );
      setBankInfo(transferPaymentMethod);
    }
  }, [paymentMethods]);

  const calculateTotalCartPrice = () => {
    let totalProductCost = 0;
    let totalShippingCost = 0;

    cart?.cartItems?.forEach((item) => {
      const itemShippingCost = calculateShippingCost(item.product);
      const itemTotalShippingCost = itemShippingCost
        ? itemShippingCost * item.quantity
        : 0;
      totalShippingCost += itemTotalShippingCost;
      totalProductCost += item.taxPrice * item.quantity;
    });

    const extraCharge = selectedPaymentMethod?.extraCharge || 0;
    const baseTotalCost = totalProductCost + totalShippingCost;
    const additionAmount = (baseTotalCost * additionRate) / 100;
    const grandTotal =
      baseTotalCost + additionAmount + (baseTotalCost * extraCharge) / 100;

    return { totalShippingCost, grandTotal };
  };

  const { totalShippingCost, grandTotal } = calculateTotalCartPrice();
  const screen = localStorage.getItem("screen") || "large";

  if (isLoadingCheckout || isLoading) {
    return <Loader />;
  } else {
    return (
      <>
        <div
          className={`row ${lang === "ar" ? "arabic-text" : ""}`}
          style={{ justifyContent: "space-around" }}
        >
          {/* Order summary START */}
          <div className="col-lg-10 mx-auto">
            <div className="order-summary">
              <h3>{t("yourOrder")}</h3>

              <table className="table table-mini-cart">
                <thead>
                  <tr>
                    <td style={{ border: "none" }} colSpan="2">
                      <h4>{t("product")}</h4>
                    </td>
                    <td style={{ border: "none" }}>
                      <h4>{t("shippingCost")}</h4>
                    </td>
                  </tr>
                </thead>
                <tbody>
                  {cart?.cartItems?.map((item, i) => {
                    const shippingCost = calculateShippingCost(item.product);
                    return (
                      <tr key={i}>
                        <td className="product-col">
                          <h3 className="product-title d-flex">
                            {item.name}
                            <h4 className="product-qty">
                              &nbsp;×{item.quantity}
                            </h4>
                          </h3>
                        </td>

                        <td className="price-col">
                          <h4>
                            {cur.currencyCode}
                            {(
                              item.taxPrice +
                              ((item.taxPrice * additionRate) / 100) *
                                item.quantity
                            ).toFixed(2)}
                          </h4>
                        </td>

                        <td className="shipping-cost-col">
                          <h4>
                            {shippingCost !== null
                              ? `${cur.currencyCode}${shippingCost.toFixed(2)}`
                              : "N/A"}
                          </h4>
                        </td>
                      </tr>
                    );
                  })}
                  <tr>
                    <td colSpan={2} className="product-col">
                      <h3 className="product-title">
                        {t("totalShippingCost")}
                      </h3>
                    </td>
                    <td className="shipping-cost-col">
                      <h4>
                        {cur.currencyCode}
                        {totalShippingCost.toFixed(2)}
                      </h4>
                    </td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr className="order-total">
                    <td>
                      <h4>{t("total")}</h4>
                    </td>
                    <td colSpan={2}>
                      <b className="total-price">
                        <span>
                          {cur.currencyCode}
                          {grandTotal.toFixed(2)}
                        </span>
                      </b>
                    </td>
                  </tr>
                </tfoot>
              </table>

              {/* Payments */}
              <div className="payment-methods mb-5">
                <h3>{t("paymentMethods")}</h3>
                {!isLoading && paymentMethods.length > 0 && (
                  <div
                    className={`info-box with-icon p-0 ${
                      screen === "large" ? "d-flex" : "d-block"
                    } justify-content-around mb-4`}
                  >
                    {paymentMethods
                      .filter((item) => item.status === true)
                      .map((item, index) => (
                        <div
                          key={index}
                          className="mx-auto payment-method-button"
                          style={{
                            border:
                              paymentMethod === item.name
                                ? "1px solid #ddd"
                                : "transparent",
                            cursor: "pointer",
                          }}
                          onClick={() => setPaymentMethod(item.name)}
                        >
                          <label className="cursor-pointer payment-method-label">
                            <input
                              type="radio"
                              name="paymentMethod"
                              value={item.name}
                              checked={paymentMethod === item.name}
                              onChange={handlePaymentMethodChange}
                              style={{ display: "none" }}
                            />
                            &nbsp;{t(item.name)}
                          </label>

                          {item.name === "onlinePayment" && (
                            <img
                              src={creditCard}
                              alt="credit card"
                              width={200}
                              className="mx-auto"
                            />
                          )}
                          {item.name === "bankTransfer" && (
                            <div className="iban-info mx-auto">
                              <h4>
                                <span>{t("bank")}</span>: {bankInfo?.bankName}
                              </h4>
                              <h4>
                                <span>{t("ibanNumber")}</span>:{" "}
                                {bankInfo?.ibanNumber}
                              </h4>
                              <h4>
                                <span>{t("name")}</span>: {bankInfo?.ibanName}
                              </h4>
                            </div>
                          )}
                          {item.name === "payAtDoor" && (
                            <img
                              src={payAtDoor}
                              alt="pay at door"
                              width={200}
                              className="mx-auto"
                            />
                          )}
                        </div>
                      ))}
                  </div>
                )}

                {/* Display description for the selected payment method */}
                {selectedPaymentMethod?.description && (
                  <div className="payment-description my-3">
                    <h3>{t("description")}</h3>
                    <h4>{selectedPaymentMethod.description}</h4>
                  </div>
                )}
              </div>

              <div className="d-flex">
                <h4
                  className="btn btn-dark btn-place-order mx-2"
                  onClick={goBackToAddress}
                >
                  {t("backToAddress")}
                </h4>
                <h4
                  className="btn btn-dark btn-place-order mx-2"
                  onClick={() => createOrder(paymentMethod)}
                >
                  {t("placeOrder")}
                </h4>
              </div>
            </div>
          </div>
          {/* Order summary END */}
        </div>
      </>
    );
  }
};

export default Checkout;
