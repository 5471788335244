import React from "react";
import { Check2Circle } from "react-bootstrap-icons";
import TransHook from "../../hooks/translation/trans-hook";
import { useLocation, useNavigate } from "react-router-dom";
import { ToastContainer } from "react-bootstrap";
import Navbar from "./../utility/navbar/navbar";
import Footer from "./../utility/Footer/Footer";
import { toast } from "react-toastify";

const OrderComplete = () => {
  const { t } = TransHook();
  const navigate = useNavigate();
  const location = useLocation();
  const { order } = location.state || {};
  const screen = localStorage.getItem("screen") || "large";

  if (!order) {
    toast.error("missing data");
    navigate("/");
  }

  return (
    <>
      <Navbar />
      <div
        className="container text-center"
        style={{ marginTop: screen === "large" ? "240px" : "120px" }}
      >
        <Check2Circle size={84} color="green" />
        <h1>{t("thankYou")}</h1>
        <h2 className="my-4">{t("orderCompleted")}</h2>
        <h2 className="my-4">
          {t("orderNo")}: {order.data.orderNumber}
        </h2>
        <h2 className="my-4">{t("recieveConfirm")}</h2>
        <button
          className="btn btn-warning"
          style={{ fontSize: "22px", textTransform: "capitalize" }}
          onClick={(e) => {
            e.preventDefault();
            navigate("/");
          }}
        >
          {t("goToHome")}
        </button>
      </div>
      <Footer />
      <ToastContainer autoClose={2000} />
    </>
  );
};

export default OrderComplete;
