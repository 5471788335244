import React from "react";
import { ProductBaseUrl } from "../../api/GlobalData";
import TransHook from "../../hooks/translation/trans-hook";

const OrderCard = ({ goToOrder, item, getStatus, orderStatus }) => {
  const { t } = TransHook();

  return (
    <div
      className="order_card d-flex px-4 mb-2"
      onClick={() => goToOrder(item)}
    >
      {/* Images */}
      <div className="d-flex w-25" style={{ alignItems: "center" }}>
        {item?.cartItems?.slice(0, 2).map((product, j) => (
          <img
            key={j}
            src={ProductBaseUrl + product?.product?.imagesArray[0]?.image}
            alt={product?.product?.name?.split(" ")[0]}
          />
        ))}
      </div>

      {/* Order number */}
      <div className="d-flex w-25" style={{ alignItems: "center" }}>
        <h5 className="m-0">{t("orderNo")}:</h5>&nbsp;
        <h4 className="m-0">
          <b>{item?.orderNumber}</b>
        </h4>
      </div>

      {/* Order status */}
      {getStatus(orderStatus)}

      {/* Order date and total */}
      <div style={{ alignContent: "center" }} className="w-25">
        <h5 className="m-0">{item?.date?.substring(0, 10)}</h5>
        <h4>
          <b style={{ color: "#00aa00" }}>{item?.totalOrderPrice.toFixed(3)}</b>
        </h4>
      </div>
    </div>
  );
};

export default OrderCard;
