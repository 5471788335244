import { useState } from "react";
import {
  useAddAddressItemMutation,
  useGetAddressInfoQuery,
  useRemoveAddressItemMutation,
  useUpdateAddressItemMutation,
  useGetOneAddressItemMutation,
} from "../../RTK/Api/AddressApi";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TransHook from "./../translation/trans-hook";

const AddressesHook = (onCancel = null) => {
  const [alias, setAlias] = useState("");
  const [fullName, setFullName] = useState("");
  const [city, setCity] = useState("");
  const [details, setDetails] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [phone, setPhone] = useState("");

  const handleAliasChange = (e) => setAlias(e.target.value);
  const handleFullNameChange = (e) => setFullName(e.target.value);
  const handleCityChange = (e) => setCity(e.target.value);
  const handleDetailsChange = (e) => setDetails(e.target.value);
  const handlePostalCodeChange = (e) => setPostalCode(e.target.value);
  const handlePhoneChange = (e) => setPhone(e.target.value);

  const { data: addressGet, error: addressGetError } = useGetAddressInfoQuery();
  if (addressGetError) {
    toast.error(addressGetError);
    console.error(addressGetError);
  }

  const formData = {
    name: fullName,
    alias,
    details,
    phone,
    city,
    postalCode,
  };

  const [addAddressItem, { error: addError }] = useAddAddressItemMutation();
  if (addError) {
    // toast.error(addError);
    console.error(addError);
  }

  const { t } = TransHook();

  const saveAddress = () => {
    addAddressItem(formData)
      .unwrap()
      .then(() => toast.success(t("addressAdded")))
      .catch((error) => toast.error(`${t("failedToAdd")} ${error}`));
    clearFields();
    if (onCancel) onCancel();
  };

  const clearFields = () => {
    setAlias("");
    setFullName("");
    setCity("");
    setDetails("");
    setPostalCode("");
    setPhone("");
  };

  const setFormData = (data) => {
    setAlias(data.alias);
    setFullName(data.name);
    setCity(data.city);
    setDetails(data.details);
    setPostalCode(data.postalCode);
    setPhone(data.phone);
  };

  const [removeAddressItem, { error: removeError }] =
    useRemoveAddressItemMutation();
  if (removeError) {
    // toast.error(removeError);
    console.error(removeError);
  }
  const deleteAddress = (id) => {
    removeAddressItem(id)
      .unwrap()
      .then(() => toast.success(t("itemRemoved")))
      .catch((error) => toast.error(`${t("failedToRemove")} ${error}`));
  };

  const [updateAddressItem, { error: updateError }] =
    useUpdateAddressItemMutation();
  if (updateError) {
    // toast.error(updateError);
    console.error(updateError);
  }
  const updateAddress = (id, data) => {
    updateAddressItem({ itemId: id, newItemData: data })
      .unwrap()
      .then(() => {
        toast.success(t("updateSuccess"));
        clearFields();
        onCancel();
      })
      .catch((error) => toast.error(`${t("failedToUpdate")} ${error}`));
  };

  const [getOneAddressItem, { error: getOneError }] =
    useGetOneAddressItemMutation();
  if (getOneError) {
    toast.error(getOneError);
    console.error(getOneError);
  }
  const getOneAddress = async (id) => {
    try {
      const data = await getOneAddressItem(id).unwrap();
      setFormData(data);
      return data;
    } catch (error) {
      toast.error(`${t("failedToFetchData")} ${error}`);
      throw error;
    }
  };

  return {
    addressGet,
    alias,
    fullName,
    city,
    details,
    postalCode,
    phone,
    handleAliasChange,
    handleFullNameChange,
    handleCityChange,
    handleDetailsChange,
    handlePostalCodeChange,
    handlePhoneChange,
    saveAddress,
    clearFields,
    deleteAddress,
    updateAddress,
    getOneAddress,
    setFormData,
  };
};

export default AddressesHook;
