import React from "react";
import { useParams } from "react-router-dom";
import AllReviewsHook from "../../../hooks/Review/AllReviewsHook";
import Loader from "./../../Loaders/Loader";
import Footer from "../../utility/Footer/Footer";
import { ToastContainer } from "react-toastify";
import Navbar from "../../utility/navbar/navbar";
import "../CSS/Reviews.css";
import { StarFill } from "react-bootstrap-icons";
import TransHook from "../../../hooks/translation/trans-hook";

const AllReviews = () => {
  const { id } = useParams();
  const { t } = TransHook();

  const {
    reviewList,
    isLoading,
    rateCount,
    starsCount,
    starsPercentage,
    overallRatings,
    renderStars,
  } = AllReviewsHook(id);

  if (isLoading) {
    return (
      <>
        <Navbar />
        <Loader />
        <Footer />
      </>
    );
  } else {
    return (
      <>
        <Navbar />
        <div style={{ marginTop: "240px" }} className="container d-flex">
          {/* Review summary */}
          <div className="col-12 col-lg-3">
            <div className="position-fixed">
              <h2 className="pb-3 ps-5">{t("overallRatings")}</h2>
              <div className="overall-ratings">
                <div className="text-center">
                  <p className="heading">
                    <b>{overallRatings?.toFixed(2)}</b>
                  </p>
                  {renderStars()}
                  <p className="mt-3">
                    {rateCount} {t("reviewsCounted")}
                  </p>
                </div>

                <div className="row">
                  <div className="side">
                    <div>
                      5 <StarFill color="#fb1" size={18} />
                    </div>
                  </div>
                  <div className="middle">
                    <div className="bar-container">
                      <div
                        style={{ width: `${starsPercentage[4]}%` }}
                        className="bar-5"
                      ></div>
                    </div>
                  </div>
                  <div className="side right">
                    <div>{starsCount[4]}</div>
                  </div>

                  <div className="side">
                    <div>
                      4 <StarFill color="#fb1" size={18} />
                    </div>
                  </div>
                  <div className="middle">
                    <div className="bar-container">
                      <div
                        style={{ width: `${starsPercentage[3]}%` }}
                        className="bar-4"
                      ></div>
                    </div>
                  </div>
                  <div className="side right">
                    <div>{starsCount[3]}</div>
                  </div>

                  <div className="side">
                    <div>
                      3 <StarFill color="#fb1" size={18} />
                    </div>
                  </div>
                  <div className="middle">
                    <div className="bar-container">
                      <div
                        style={{ width: `${starsPercentage[2]}%` }}
                        className="bar-3"
                      ></div>
                    </div>
                  </div>
                  <div className="side right">
                    <div>{starsCount[2]}</div>
                  </div>

                  <div className="side">
                    <div>
                      2 <StarFill color="#fb1" size={18} />
                    </div>
                  </div>
                  <div className="middle">
                    <div className="bar-container">
                      <div
                        style={{ width: `${starsPercentage[1]}%` }}
                        className="bar-2"
                      ></div>
                    </div>
                  </div>
                  <div className="side right">
                    <div>{starsCount[1]}</div>
                  </div>

                  <div className="side">
                    <div>
                      1 <StarFill color="#fb1" size={18} />
                    </div>
                  </div>
                  <div className="middle">
                    <div className="bar-container">
                      <div
                        style={{ width: `${starsPercentage[0]}%` }}
                        className="bar-1"
                      ></div>
                    </div>
                  </div>
                  <div className="side right">
                    <div>{starsCount[0]}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Reviews */}
          <div className="col-12 col-lg-9">
            <h2 className="pb-3">{t("reviews")}</h2>
            <div className="mt-2">
              {reviewList
                .slice()
                .reverse()
                .map((review, i) => {
                  if (review.review && review.review.length > 0) {
                    return (
                      <div key={i} className="d-flex review-container">
                        <div className="col-12 col-lg-3 review-left">
                          {renderStars(review.rating, 16)}
                          <p className="review-name">
                            {review?.customar?.name?.substring(0, 3)}***
                          </p>
                          <p className="review-date">
                            {new Date(review.createdAt)
                              .toLocaleDateString("en-GB")
                              .split("/")
                              .join("-")}
                          </p>
                        </div>
                        <div className="col-12 col-lg-9 review-right">
                          <p className="review-content">{review.review}</p>
                        </div>
                      </div>
                    );
                  }
                })}
            </div>
          </div>
        </div>
        <Footer />
        <ToastContainer autoClose={2000} />
      </>
    );
  }
};

export default AllReviews;
